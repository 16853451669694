@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Prata&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.brochure {
    margin: 0 -15px;
    background-color: #101010;

    .pages{
        padding: 0 calc(20vw - 10px);
        height: 45vw;
        display: flex;
        justify-content: center;
    }

    .page{
        width:30vw;
        height:45vw;
    }

    .page1, .page4, .page5{
        background-color: #FFFAEB;
    }

    .page2, .page3, .page6{
        background-color: #4B5648;
    }

    .welcome{
        text-align: center;
        padding: 12vw 0;
        color: #A3B18A;

        h1,h2,h3,h4,h5,h6{
            font-family: "Kalnia";
            font-weight: 380;
            line-height: 2.2vw;
            letter-spacing: .08vw;
        }

        p{
            font-family:"Comfortaa", serif;
            font-size: 1.15vw;
            font-weight: 400;
            line-height: 1vw;
            letter-spacing: .1vw;
        }

        .date{
            font-family:"Work Sans", serif;
            font-size: 1vw;
        }

        h1{
            font-size: 3vw;
        }

        h2{
            font-size: 2.8vw;
        }

        h3{
            font-size: 2.2vw;
        }

        h4{
            font-size: 2vw;
        }

        h5{
            font-size: 1.8vw;
        }

        h6{
            font-size: 1.5vw;
        }
    }

    .mission{
        padding: calc(4vw) 1.5vw;
        text-align: center;
        font-family:"Work Sans", serif;
        color: #A3B18A;
        font-size: .9vw;
        font-weight: 350;
    }

    .page2{
        padding:4vw 5.5vw;
    }

    .icon{
        width:5vw;

        img{
            width: 100%;
            border:2px solid white;
            border-radius: 5vw;
        }
    }
    
    .getInTouch{
        padding: 2vw 0;
        color: #FFFAEB;
        
        h1,h2,h3,h4,h5,h6{
            font-family: "Kalnia";
            font-weight: 200;
            line-height: 2.2vw;
            letter-spacing: .08vw;
            padding-bottom: 2vw;
        }

        p{
            b{
                font-weight: 600;
            }
            font-family:"Work Sans", serif;
            font-size: .9vw;
            font-weight: 300;
            letter-spacing: .1vw;
            line-height: .5vw;
        }

        h1{
            font-size: 2.8vw;
        }

        h2{
            font-size: 2.4vw;
        }

        h3{
            font-size: 1.8vw;
        }

        h4{
            font-size: 1.6vw;
        }

        h5{
            font-size: 1.4vw;
        }

        h6{
            font-size: 1.2vw;
        }
    }

    .socials{
        height: 4vw;

        .social{
            display: flex;

            img{
                margin:auto 0;
                width: 1.2vw;
                float: left;
                height: 1.2vw;
            }
            
            .handle{
                width:auto;
                padding: 0 1vw;
                font-family:"Work Sans", serif;
                font-size: .9vw;
                font-weight: 300;
                letter-spacing: .1vw;
                color: #FFFAEB;
            }
        }
    }

    .address{
        padding: 2vw 0;
        color: #FFFAEB;

        h1,h2,h3,h4,h5,h6{
            font-family: "Kalnia";
            font-weight: 200;
            line-height: 2.2vw;
            letter-spacing: .08vw;
            padding-bottom: 2vw;
        }
        
        p{
            b{
                font-weight: 600;
            }
            font-family:"Work Sans", serif;
            font-size: .9vw;
            font-weight: 300;
            letter-spacing: .1vw;
            line-height: .5vw;
        }

        h1{
            font-size: 2.8vw;
        }

        h2{
            font-size: 2.4vw;
        }

        h3{
            font-size: 1.8vw;
        }

        h4{
            font-size: 1.6vw;
        }

        h5{
            font-size: 1.4vw;
        }

        h6{
            font-size: 1.2vw;
        }
    }

    .page3, .page6{
        padding:4vw 2vw;
        text-align: center;
        color: #FFFAEB;
        font-family:"Work Sans", serif;

        p{
            b{
                font-weight: 600;
            }
            font-size: .9vw;
            font-weight: 300;
            letter-spacing: .1vw;
            line-height: 1vw;
        }

        h1, h2, h3, h4, h5, h6{
            font-weight: 200;
            letter-spacing: .08vw;
        }

        h1{
            font-size: 2.4vw;
        }

        h2{
            font-size: 2.1vw;
        }

        h3{
            font-size: 1.8vw;
        }

        h4{
            font-size: 1.6vw;
        }

        h5{
            font-size: 1.4vw;
        }

        h6{
            font-size: 1.2vw;
        }

        hr{
            width:80%;
            border: 2px solid currentColor;
        }

        .ca{
            width:26vw;
            max-height: 18vw;
            overflow-x: hidden;

            p:last-child{
                margin-bottom: 0;
            }
        }

        .lp{
            margin-top: 4vw;

            p{
                margin-bottom: .1vw;
            }
            
            p:first-child{
                margin-bottom: .4vw;
            }
        }

        ::-webkit-scrollbar {
            width: 12px;
        }
        
        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(200,200,200,1);
            border-radius: 10px;
        }
        
        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color:#A3B18A;
            -webkit-box-shadow: inset 0 0 6px rgba(90,90,90,0.7);
        }
    }

    .page4, .page5{
        padding:4vw 2vw;
        text-align: center;
        color: #4B5648;
        font-family:"Work Sans", serif;
        overflow: hidden;

        p{
            b{
                font-weight: 600;
            }
            font-size: .9vw;
            font-weight: 300;
            letter-spacing: .05vw;
            line-height: 1.2vw;
        }

        h1, h2, h3, h4, h5, h6{
            font-weight: 200;
            letter-spacing: .08vw;
        }

        h1{
            font-size: 2.4vw;
        }

        h2{
            font-size: 2.1vw;
        }

        h3{
            font-size: 1.8vw;
        }

        h4{
            font-size: 1.6vw;
        }

        h5{
            font-size: 1.4vw;
        }

        h6{
            font-size: 1.2vw;
        }

        .ctw{
            width:26vw;
            max-height: 22vw;
            overflow-x: hidden;
        }

        .hc{
            margin-top: 4vw;
            width:26vw;
            max-height: 11vw;
            overflow-x: hidden;
            position: relative;

            a{
                text-decoration: none;
                color: currentColor;
            }
        }

        .pp, .dox, .scripture, .sermon, .ref, .off{
            margin-bottom: 2vw;
            p{
                line-height: .4vw;
            }
        }

        .sermon{
            width:26vw;
            max-height: 11vw;
            overflow-x: hidden;
            p{
                line-height: 1.2vw;
                margin-bottom: 0;
            }
        }

        ::-webkit-scrollbar {
            width: 12px;
        }
        
        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(200,200,200,1);
            border-radius: 10px;
        }
        
        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color:#A3B18A;
            -webkit-box-shadow: inset 0 0 6px rgba(90,90,90,0.7);
        }
    }
}
