// Templates
@import 'templateA.scss';
@import 'templateB.scss';
@import 'templateC.scss';
@import 'templateD.scss';
@import 'customs.scss';
@import 'openlp.scss';


// Mobile
@import 'mobile/mobileA.scss';
@import 'mobile/mobileB.scss';
@import 'mobile/mobileC.scss';
@import 'mobile/mobileD.scss';
@import 'mobile/mobileCustoms.scss';


// Plugins
@import "~react-image-gallery/styles/scss/image-gallery.scss";


// NBP-55 HotFix Issue
@media only screen and (max-width: 1024px){
    html, body { overflow-x: hidden; }
}

// Custom Redirect
.custom-redirect{
    width:100vw;
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
