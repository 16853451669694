@media only screen and (orientation: portrait){
    .brochure{
        .pages{
            padding: 0;
            flex-direction: column;
            height:calc(3 * 100vw);
        }

        .page{
            width:100vw;
            height:calc(1.5 * 100vw);
        }

        .page1, .page3, .page5{
            background-color: #FFFAEB;
        }
    
        .page2, .page4, .page6{
            background-color: #4B5648;
        }

        .welcome{
            padding: calc(12vw * 3.334) 0 calc(11vw * 3.334);
            color: #A3B18A;
    
            h1,h2,h3,h4,h5,h6{
                line-height: calc(2.2vw * 3.334);
                letter-spacing: calc(.08vw * 3.334);
            }
    
            p{
                font-size: calc(1.15vw * 3.334);
                line-height: calc(1vw * 3.334);
                letter-spacing: calc(.1vw * 3.334);
            }
    
            h1{
                font-size: calc(3vw * 3.334);
            }
    
            h2{
                font-size: calc(2.8vw * 3.334);
            }
    
            h3{
                font-size: calc(2.2vw * 3.334);
            }
    
            h4{
                font-size: calc(2vw * 3.334);
            }
    
            h5{
                font-size: calc(1.8vw * 3.334);
            }
    
            h6{
                font-size: calc(1.5vw * 3.334);
            }

            .date{
                font-size: calc(1vw * 3.334);
            }
        }
    
        .mission{
            padding: calc(4vw * 3.334) calc(1.5vw * 3.334);
            font-size: calc(.9vw * 3.334);
        }
    
        .page2{
            padding:calc(4vw * 3.334) calc(5.5vw * 3.334);
        }
    
        .icon{
            width:calc(3vw * 3.334);
    
            img{
                border-radius: calc(5vw * 3.334);
            }
        }
        
        .getInTouch{
            padding: calc(2vw * 3.334) 0;
            
            h1,h2,h3,h4,h5,h6{
                line-height: calc(2.2vw * 3.334);
                letter-spacing: calc(.08vw * 3.334);
                padding-bottom: calc(2vw * 3.334);
            }
    
            p{
                font-size: calc(.9vw * 3.334);
                letter-spacing: calc(.1vw * 3.334);
                line-height: calc(.5vw * 3.334);
            }
    
            h1{
                font-size: calc(2.8vw * 3.334);
            }
    
            h2{
                font-size: calc(2.4vw * 3.334);
            }
    
            h3{
                font-size: calc(1.8vw * 3.334);
            }
    
            h4{
                font-size: calc(1.6vw * 3.334);
            }
    
            h5{
                font-size: calc(1.4vw * 3.334);
            }
    
            h6{
                font-size: calc(1.2vw * 3.334);
            }
        }
    
        .socials{
            height: calc(4vw * 3.334);
    
            .social{
    
                img{
                    width: calc(1.2vw * 3.334);
                    height: calc(1.2vw * 3.334);
                }
                
                .handle{
                    padding: 0 calc(1vw * 3.334);
                    font-size: calc(.9vw * 3.334);
                    letter-spacing: calc(.1vw * 3.334);
                }
            }
        }
    
        .address{
            padding: calc(2vw * 3.334) 0;
            color: #FFFAEB;
    
            h1,h2,h3,h4,h5,h6{
                line-height: calc(2.2vw * 3.334);
                letter-spacing: calc(.08vw * 3.334);
                padding-bottom: calc(2vw * 3.334);
            }
            
            p{
                font-size: calc(.9vw * 3.334);
                letter-spacing: calc(.1vw * 3.334);
                line-height: calc(.5vw * 3.334);
            }
    
            h1{
                font-size: calc(2.8vw * 3.334);
            }
    
            h2{
                font-size: calc(2.4vw * 3.334);
            }
    
            h3{
                font-size: calc(1.8vw * 3.334);
            }
    
            h4{
                font-size: calc(1.6vw * 3.334);
            }
    
            h5{
                font-size: calc(1.4vw * 3.334);
            }
    
            h6{
                font-size: calc(1.2vw * 3.334);
            }
        }
    
        .page3{
            padding:calc(4vw * 3.334) calc(2vw * 3.334);
            color: #4B5648;
    
            p{
                font-size: calc(.9vw * 3.334);
                letter-spacing: calc(.1vw * 3.334);
                line-height: calc(.6vw * 3.334);
            }
    
            h1, h2, h3, h4, h5, h6{
                letter-spacing: calc(.08vw * 3.334);
            }
    
            h1{
                font-size: calc(2.2vw * 3.334);
            }
    
            h2{
                font-size: calc(2.1vw * 3.334);
            }
    
            h3{
                font-size: calc(1.8vw * 3.334);
            }
    
            h4{
                font-size: calc(1.6vw * 3.334);
            }
    
            h5{
                font-size: calc(1.4vw * 3.334);
            }
    
            h6{
                font-size: calc(1.2vw * 3.334);
            }
    
            hr{
                width:80%;
                border: 2px solid currentColor;
            }
        }
    
        .page4, .page5, .page6{
            padding:calc(4vw * 3.334) calc(2vw * 3.334);
            color: #FFFAEB;
    
            p{
                font-size: calc(.9vw * 3.334);
                letter-spacing: calc(.05vw * 3.334);
                line-height: calc(1.2vw * 3.334);
            }
    
            h1, h2, h3, h4, h5, h6{
                letter-spacing: calc(.08vw * 3.334);
            }
    
            h1{
                font-size: calc(2.2vw * 3.334);
            }
    
            h2{
                font-size: calc(2.1vw * 3.334);
            }
    
            h3{
                font-size: calc(1.8vw * 3.334);
            }
    
            h4{
                font-size: calc(1.6vw * 3.334);
            }
    
            h5{
                font-size: calc(1.4vw * 3.334);
            }
    
            h6{
                font-size: calc(1.2vw * 3.334);
            }
    
            .ctw{
                width:calc(26vw * 3.334);
                max-height: calc(22vw * 3.334);

                p:last-child{
                    margin-bottom: 0;
                }
            }
    
            .hc{
                margin-top: calc(4vw * 3.334);
                width:calc(26vw * 3.334);
                max-height: calc(11vw * 3.334);
            }

            .ca{
                width:calc(26vw * 3.334);
                max-height: calc(18vw * 3.334);
            }

            .lp{
                margin-top: calc(4vw * 3.334);
            }
    
            ::-webkit-scrollbar {
                width: 12px;
            }
            
            ::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(200,200,200,1);
                border-radius: 10px;
            }
            
            ::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color:#A3B18A;
                -webkit-box-shadow: inset 0 0 6px rgba(90,90,90,0.7);
            }
        }

        .page5{
            color: #4B5648;

            .pp, .dox, .scripture, .sermon, .ref, .off{
                margin-bottom: calc(2vw * 3.334);
                p{
                    line-height: calc(.4vw * 3.334);
                }
            }

            .sermon{
                width:calc(26vw * 3.334);
                max-height: calc(11vw * 3.334);
                overflow-x: hidden;
                p{
                    line-height: calc(1.2vw * 3.334);
                }
            }
        }
    }
}