@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Prata&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.openLP{
    background-color: #4B5648;
    text-align: center;
    width: 100vw;
    height: 100vh;
    margin: 0 -15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color:#FFFAEB;
    padding: 4vw;

    font-family:"Work Sans", serif;
    font-size: 1vw;
}

#welcome{
    text-transform: uppercase;

    h2{
        font-size: 2em;
        line-height: 5.5vw;
    }

    font-size: 3vw;
    font-weight: 400;
    line-height: 4vw;

    img{
        width: 12vw;
        height: 12vw;
    }
}

#ctw, #ctw1, #ctw2, #ctw3, #ctw4, #hc, #sm, #announce, #announce1, #announce2, #announce3{
    font-size: 2vw;

    p{
        font-size: 1em;
        b{
            font-weight: 600;
        }
    }

    a{
        text-decoration: none;
        color: currentColor;
    }
    
    padding: 8vw;
}